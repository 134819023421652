import React, { useState, useEffect } from 'react';
import PageTitle from '../../../Utils/PageTitle';
import { ContainerRow, TitleRow } from '../styledComponents';
import { StyledJobListSubTab } from './styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import { Tabs, Table } from 'antd';
import CustomSideDrawer from '../../CustomComponents/CustomSideDrawer';
import {
  TableDiv,
  StyledJobListTabPane,
  StyledJobListTab,
  StyledJobListTabPaneContent,
  StyledJobListTabPaneContentText,
  StyledJobListTabPaneContentCount,
  ConfigureButton,
  StyledContent,
  FilterButtonTittle,
  StyledResetButton,
  FilterBadge
} from './styledComponents';
import JobListTable from './JobListTable';
import {
  getJobs,
  transcriptorSelfAssignJob,
  startTranscriptorJob
} from '../../../Apis/Transcriptor';
import { getListofJobsForMyJobsTabDoctor } from '../../../Apis/Doctor';
import {
  getIncompleteJobs,
  releaseJob,
  retryIncompleteJobSubmission
} from '../../../Apis/Jobs';
import {
  reviewerSelfAssignJob,
  startReviewerJob,
  getListOfJobsReview
} from '../../../Apis/Reviewer';
import { RESULTS_PER_PAGE } from '../../../Constants/StringConstants';
import { GetJobDashboardCount } from '../../../Apis/Dashboard';
import FilterConfiguration from '../../CustomComponents/FilterConfiguration';
import VectorIcon from '../../../Assets/Icons/VectorIcon';
import { GetJobCount } from '../../../Apis/Jobs';
import { Badge, notification, Space } from 'antd';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { getReviewerJobs } from '../../../Apis/Reviewer';
import { sideBarContents } from './SidebarConfig';
import Spinner from '../../CustomComponents/Spinner';
import { clearJobNotification } from '../../../Redux/Actions/Notification';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import ReleaseJobModal from '../../CustomComponents/ReleaseJobModal';
import { SubmitButton, SubmitButtonCol } from '../../Content/styledComponents';
import {
  getUserFacilities,
  getJobFilterConfig,
  saveConfiguration,
  fetchFacilityAuthor
} from '../../../Apis/User';

const doctorPrivilege = new Map([
  ['TRANSCRIPTION_ONLY', 'transcriptionist'],
  ['REVIEWER_ONLY', 'reviewer'],
  ['TRANSCRIPTION_REVIEWER', 'admin'],
  ['NONE', 'None']
]);
const { TabPane } = Tabs;
const JobList = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromEditor = searchParams.get('fromEditor') === 'true';
  const [theme] = useSelector(state => [state.themeReducer]);
  const user = useSelector(state => state.userReducer);
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const notificationStatus = useSelector(state => state.notificationReducer);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [activeTab1, setActiveTab1] = useState('');
  const [userType1, setUserType1] = useState();
  const [status, setStatus] = useState();
  // const [page, setSpage] = useState();
  const [availableJobs, setAvailableJobs] = useState([]);
  const [filterKey, setFilterKey] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [sortOrder, setSortOrder] = useState([]);
  const [assignButtonLoading, setAssignButtonLoading] = useState(false);
  const [sideBarItems, setSideBarItems] = useState();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [activeTab, setActivetab] = useState('doctor');
  const [activeIncompleteTab, setActiveIncompletetab] =
    useState('unsuccessful');
  const [selfAssignTranscriptorBadge, setSelfAssignedTranscriptorBadge] =
    useState(false);
  const [selfAssignReviewerBadge, setSelfAssignedReviewerBadge] =
    useState(false);
  const [userType, setUserType] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const [jobStatus, setJobStatus] = useState('');
  const [counts, setCounts] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [resubmitLoading, setResubmitLoading] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [resubmitModalVisible, setResubmitModalVisible] = useState(false);
  const [resubmitModalLoading, setResubmitModalLoading] = useState(false);
  const [resubmitJobIds, setResubmitJobIds] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);
  const [updatedFilterConfig, setUpdatedFilterConfig] = useState([]);
  const [configLoader, setconfigLoader] = useState(false);
  const [filterCount, setfilterCount] = useState(0);
  const [selectedTab, setselectedTab] = useState('');

  // const [activeKey, setActiveKey] = useState(() => {
  //   let activeItem;
  //   if (sideBarItems) {
  //     activeItem = sideBarItems.find(item => {
  //       let splitPath = location.pathname.split('/');
  //       let splitLink = item.path.split('/');
  //       if (splitPath[3] === splitLink[3]) return item;
  //       else return null;
  //     });
  //   }
  //   console.log('hi...1', sideBarItems, activeItem && activeItem.key);
  //   return activeItem && activeItem.key;
  // });
  const [activeKey, setActiveKey] = useState('0');
  const [releaseJobModalVisible, setReleaseJobModalVisible] = useState(false);
  const [releaseJobId, setReleaseJobId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [authors, setAuthors] = useState();
  const [userfacilities, setUserFacilities] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const getFacilities = () => {
    getUserFacilities().then(response => {
      if (response.data.success) {
        setUserFacilities(response.data.result);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
      }
    });
  };
  const getUserRole = () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return null;

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.scopes || null;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  useEffect(() => {
    const role = getUserRole();
    setUserRoles(role);
    console.log('User Role:', role); // This will run only once
    setUserRole(role);
    if (role?.includes('ROLE_DOCTOR') || role?.includes('ROLE_ADMIN')) {
      // setActiveTab1('tab1');

      setActiveKey('0');
      handleTabChange('doctor');
      // setSideBarItems(sideBarContents('doctor'));
    } else if (role?.includes('ROLE_REVIEWER')) {
      setStatus('READY');
      // setActiveTab1('tab2');
      // setSideBarItems(sideBarContents('reviewer'));
      setActiveKey('4');
      handleTabChange('reviewer');
    } else {
      setActiveTab1(null); // Hide tabs if no matching role
      handleTabChange(null);
    }
  }, []);
  useEffect(() => {
    getFacilities();
    getAuthors();
    // getJobConfiguration();
    // setLoading(true);
  }, []);

  useEffect(() => {
    setfilterCount(0);
    Object.keys(filterConfig).forEach(function (key, index) {
      if (filterConfig[key].length > 0) {
        setfilterCount(1);
      }
    });
  }, [filterConfig]);

  useEffect(() => {
    setAvailableJobs([]);

    setStatus(statusMapping[activeKey]);
    // fetchJobList(0, activeTab1);
  }, [activeKey]);
  const handleTabChange = key => {
    console.log('log1', status);
    // setUserType1(key);
    // if (searchParams.get('fromEditor') === 'true') {
    //   history.push(location.pathname); // Keep the same path, just remove the query params
    // } else {
    //   if (key === 'doctor') {
    //     history.push(`/dashboard/jobs-list/my-jobs/in-progress`);
    //   } else {
    //     history.push(`/dashboard/jobs-list/jobs-to-review/ready`);
    //   }
    // }
    console.log('FromEditor Param:', fromEditor);
    console.log('Current Pathname:', location.pathname);

    if (fromEditor) {
      const statusMapping1 = {
        ['in-progress']: { status: 'INPROGRESS', index: '0' },
        ['in-review']: { status: 'INREVIEW', index: '1' },
        ['completed']: { status: 'COMPLETE', index: '2' },
        ['incomplete']: { status: 'INCOMPLETE', index: '3' },
        ['ready']: { status: 'READY', index: '4' }
      };
      const pathSegments = location.pathname.split('/');
      key =
        pathSegments[pathSegments.length - 2] === 'my-jobs'
          ? 'doctor'
          : 'reviewer';
      console.log('hel', pathSegments[pathSegments.length - 2], key);

      const jobStatusFromURL = pathSegments[pathSegments.length - 1];

      const { status: jobStatus1, index: jobStatusIndex } = statusMapping1[
        jobStatusFromURL
      ] || { status: 'INPROGRESS', index: 0 }; // Default
      setUserType1(key);
      const cleanPath = location.pathname.split('?')[0]; // Remove query params
      history.push(cleanPath);
      setActiveKey(jobStatusIndex);
      setActiveTab1(key);

      setSideBarItems(sideBarContents(key));
      updateStatus(jobStatus1);
    } else {
      setUserType1(key);
      if (key === 'doctor') {
        history.push(`/dashboard/jobs-list/my-jobs/in-progress`);
      } else {
        history.push(`/dashboard/jobs-list/jobs-to-review/ready`);
      }
      setActiveKey(key === 'doctor' ? '0' : '4');
      setActiveTab1(key);

      setSideBarItems(sideBarContents(key));
      updateStatus(key === 'doctor' ? 'INPROGRESS' : 'READY');
      // getJobConfiguration();
    }

    // setActiveKey(key === 'doctor' ? '0' : '4');
    // setActiveTab1(key);

    // setSideBarItems(sideBarContents(key));
    // updateStatus(key === 'doctor' ? 'INPROGRESS' : 'READY');

    console.log('log2', status);
  };
  const updateStatus = newStatus => {
    setStatus(prev => (prev === newStatus ? '' : newStatus)); // Temporarily set different value
    setTimeout(() => setStatus(newStatus), 0); // Reassign the same value after a small delay
  };
  function getObject(obj, value) {
    let data = { found: false, res: [] };
    const foundItem = obj.find(item => item.key === value);
    if (foundItem) {
      data.res = foundItem.values;
      data.found = true;
    }

    return data;
  }

  const modifyFilterResponse = resp => {
    let result = { facilityIds: [], doctorIds: [], status: [] };
    let found = { facilityIds: false, doctorIds: false, status: false };
    if (resp.length !== 0 && Boolean(resp[0]['filterObject'])) {
      let myList = resp[0]['filterObject'];
      Object.keys(result).map((item, index) => {
        let resp = getObject(myList, item);
        result[item] = resp.res;
        found[item] = resp.found;
      });
    }
    const isEmptyConfig = Object.values(found).every(v => v === false);
    if (user && user.userType === 'doctor' && isEmptyConfig) {
      result['doctorIds'].push(user?.id);
    }
    let res = JSON.stringify(result);
    setFilterConfig(JSON.parse(res));
    setUpdatedFilterConfig(JSON.parse(res));
  };

  const getJobConfiguration = activeTab1 => {
    getJobFilterConfig(
      activeTab1 === 'doctor' ? 'myJobPage' : 'reviewerPage'
    ).then(response => {
      if (response.data.success) {
        modifyFilterResponse(response?.data?.result);
      } else {
        notification['error']({
          message: 'Configuration Fetch Failed',
          description: response.data.message
        });
      }
    });
  };
  const getAuthors = () => {
    fetchFacilityAuthor().then(response => {
      if (response.data.success) {
        setAuthors(response.data.result);
      } else {
        notification['error']({
          message: 'Authors Fetch Failed',
          description: response.data.message
        });
      }
    });
  };

  const onSelect = key => {
    console.log('hi3', userType, key);
    setActiveKey(key);
  };

  const SideBarCallsMap = new Map([
    ['0', 'in-progress'],
    ['1', `in-review`],
    ['2', 'completed'],
    ['3', 'incomplete'],
    ['4', 'ready']
  ]);
  const statusMapping = {
    0: 'INPROGRESS',
    1: 'INREVIEW',
    2: 'COMPLETE',
    3: 'INCOMPLETE',
    4: 'READY'
  };

  const type = SideBarCallsMap.get(activeKey);

  const fetchSidebarList = () => {
    if (sideBarItems) {
      if (
        user.userType === 'admin' ||
        user.userType === 'clinicAdmin' ||
        (user.userType === 'doctor' &&
          user.userPrivilege === 'TRANSCRIPTION_REVIEWER')
      ) {
        // GetJobCount()
        //   .then(response => {
        //     if (response.data.success) {
        //       const counts = response.data.result[0];
        //       setCounts(counts);
        //       sideBarItems.map(
        //         item =>
        //           (item.count =
        //             counts[item.countValueKey].reviewerJobCount +
        //             counts[item.countValueKey].transcriptionistJobCount)
        //       );
        //       setLoading(false);
        //     }
        //   })
        //   .catch(err => {
        //     setLoading(false);
        //   });
      } else {
        // GetJobDashboardCount(userType)
        //   .then(response => {
        //     if (response.data.success) {
        //       const counts = response.data.result[0];
        //       setCounts(counts);
        //       if (userType === 'transcriptionist')
        //         sideBarItems.map(
        //           item => (item.count = counts[item.countValueKey])
        //         );
        //       else {
        //         sideBarItems.map(
        //           item =>
        //             (item.count =
        //               counts[
        //                 item.countValueKey
        //                   .slice(0, item.countValueKey.length - 14)
        //                   .concat('Jobs')
        //               ])
        //         );
        //       }
        //       setLoading(false);
        //     }
        //   })
        //   .catch(err => {
        //     setLoading(false);
        //   });
      }
    }
  };

  // const fetchJobList = (pageNo = 0, userType) => {
  //   console.log('tet1', status, userType);

  //   setTableLoading(true);
  //   if (status && userType === 'doctor') {
  //     console.log('tet4', status, userType);
  //     getListofJobsForMyJobsTabDoctor(
  //       pageNo,
  //       RESULTS_PER_PAGE,
  //       filterKey,
  //       sortOrder,
  //       status,
  //       searchKey
  //     )
  //       .then(response => {
  //         if (response.data.success) {
  //           setTotal(response.data.size);
  //           setAvailableJobs(response.data.result);
  //           setTableLoading(false);
  //         } else {
  //           notification['error']({
  //             message: 'Failed',
  //             description: response.data.message
  //           });
  //           setTableLoading(false);
  //         }
  //       })
  //       .catch(err => {
  //         setTableLoading(false);
  //       });
  //   } else if (status && userType === 'reviewer') {
  //     console.log('tet5', status, userType);
  //     getListOfJobsReview(
  //       pageNo,
  //       RESULTS_PER_PAGE,
  //       filterKey,
  //       sortOrder,
  //       status
  //     )
  //       .then(response => {
  //         if (response.data.success) {
  //           setTotal(response.data.size);
  //           setAvailableJobs(response.data.result);
  //           setTableLoading(false);
  //         } else {
  //           notification['error']({
  //             message: 'Failed',
  //             description: response.data.message
  //           });
  //           setTableLoading(false);
  //         }
  //       })
  //       .catch(err => {
  //         setTableLoading(false);
  //       });
  //   }
  // };

  useEffect(() => {
    if (user) {
      const userType =
        ['admin', 'clinicAdmin'].includes(user.userType) ||
        doctorPrivilege.get(user.userPrivilege) === 'admin'
          ? 'transcriptionist'
          : user.userType === 'doctor'
          ? doctorPrivilege.get(user.userPrivilege)
          : user.userType;
      setUserType(userType);
      // setSideBarItems(sideBarContents(userType));
    }
  }, [user]);
  useEffect(() => {
    console.log('tet', activeKey);
    getJobConfiguration(activeTab1);
  }, [activeTab1]);

  // useEffect(() => {
  //   if (sideBarItems) {
  //     const activeItem = sideBarItems.find(item => {
  //       let splitPath = location.pathname.split('/');
  //       let splitLink = item.path.split('/');
  //       if (splitPath[3] === splitLink[3]) return item;
  //       else return null;
  //     });
  //     if (activeItem) {
  //       setActiveKey(activeItem.key);
  //     } else {
  //       setActiveKey('0');
  //     }
  //   }
  // }, [sideBarItems, location.pathname]);

  useEffect(() => {
    return () => {
      dispatch(clearJobNotification());
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (type === 'completed' || 'incomplete') {
      if (
        sideBarItems?.filter(data => data.name === type).count !==
        availableJobs.length
      ) {
        fetchSidebarList();
      }
    }
    // eslint-disable-next-line
  }, [availableJobs, type, sideBarItems]);
  useEffect(() => {
    fetchSidebarList();
    // eslint-disable-next-line
  }, [sideBarItems, notificationStatus.jobNotification]);

  // useEffect(() => {
  //   if (notificationStatus.jobNotification.newJobAvailable) {
  //     if (type === 'ready') fetchJobList(currentPage);
  //   }
  //   // eslint-disable-next-line
  // }, [notificationStatus.jobNotification.newJobAvailable]);

  // useEffect(() => {
  //   if (notificationStatus.jobNotification.writeFailed && type === 'incomplete')
  //     fetchJobList(currentPage);
  //   // eslint-disable-next-line
  // }, [notificationStatus.jobNotification.writeFailed]);

  // useEffect(() => {
  //   if (status) {
  //     // Only run if status is set
  //     fetchJobList(0, activeTab1);

  //   }

  //   // eslint-disable-next-line
  // }, [
  //   filterKey,
  //   sortOrder,
  //   status,
  //   // sideBarItems,
  //   searchKey,
  //   jobStatus,
  //   facilities
  // ]);

  const fetchJobList = async (pageNo = 0, userType) => {
    console.log('unknown');

    console.log('Fetching jobs for:', userType);

    setTableLoading(true);

    try {
      let response;
      if (userType === 'doctor') {
        console.log('Fetching My Jobs for Doctor');
        response = await getListofJobsForMyJobsTabDoctor(
          pageNo,
          RESULTS_PER_PAGE,
          filterKey,
          sortOrder,
          status,
          searchKey
        );
      } else if (userType === 'reviewer') {
        console.log('Fetching Reviewer Jobs');
        response = await getListOfJobsReview(
          pageNo,
          RESULTS_PER_PAGE,
          filterKey,
          sortOrder,
          status,
          searchKey
        );
      }

      if (response?.data?.success) {
        setTotal(response.data.size);
        setAvailableJobs(response.data.result);
      } else {
        notification['error']({
          message: 'Failed',
          description: response?.data?.message || 'Unknown error'
        });
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Request was cancelled due to tab switch');
      } else {
        console.error('API Error:', err);
        notification['error']({
          message: 'Error',
          description: 'Failed to fetch job list'
        });
      }
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (!status) return; // Only run if status is set

    fetchJobList(currentPage, activeTab1);
  }, [
    filterKey,
    sortOrder,
    status,
    searchKey,
    jobStatus,
    facilities,
    currentPage
  ]); // Added activeTab1

  const pageNoChangeHandler = pageNo => {
    console.log('grh', pageNo, selectedTab);
    setCurrentPage(pageNo);
    // fetchJobList(pageNo, activeTab1);
  };

  const assignToSelf = jobId => {
    console.log('tet3');

    setAssignButtonLoading(true);
    // userType === 'transcriptionist' &&
    //   transcriptorSelfAssignJob(jobId, user.sessionId)
    //     .then(response => {
    //       if (response.data.success) {
    //         notification['success']({
    //           message: 'Updated',
    //           description: response.data.message
    //         });
    //         fetchJobList(currentPage);
    //         fetchSidebarList();
    //         setAssignButtonLoading(false);
    //       } else {
    //         notification['error']({
    //           message: 'Failed',
    //           description: response.data.message
    //         });
    //         setAssignButtonLoading(false);
    //       }
    //     })
    //     .catch(err => {
    //       setAssignButtonLoading(false);
    //     });

    // userType === 'reviewer' &&
    reviewerSelfAssignJob(jobId, user.sessionId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Updated',
            description: response.data.message
          });
          fetchJobList(currentPage);
          fetchSidebarList();
          setAssignButtonLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setAssignButtonLoading(false);
        }
      })
      .catch(err => {
        setAssignButtonLoading(false);
      });
  };

  const startAssignedJob = jobId => {
    userType === 'transcriptionist' &&
      startTranscriptorJob(jobId).then(response => {
        if (response.data.success) {
          history.push(`/dashboard/editor/${jobId}?userRole=${userType1}`);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });

    userType === 'reviewer' &&
      startReviewerJob(jobId).then(response => {
        if (response.data.success) {
          history.push(`/dashboard/editor/${jobId}?userType=${userType}`);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });
  };

  const startAssignedJobWithNewEditor = jobId => {
    // userType === 'transcriptionist' &&
    //   // startTranscriptorJob(jobId).then(response => {
    //   transcriptorSelfAssignJob(jobId, user.sessionId).then(response => {
    //     if (response.data.success) {
    //       history.push(
    //         `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
    //           activeKey
    //         )}&userType=${userType}`
    //       );
    //     } else {
    //       notification['error']({
    //         message: 'Failed',
    //         description: response.data.message
    //       });
    //     }
    //   });

    // userType === 'reviewer' &&
    // startReviewerJob(jobId).then(response => {
    reviewerSelfAssignJob(jobId, user.sessionId).then(response => {
      if (response.data.success) {
        history.push(
          `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
            activeKey
          )}&userRole=${userType1}`
        );
      } else {
        notification['error']({
          message: 'Failed',
          description: response.data.message
        });
      }
    });
  };

  const resumeJob = jobId => {
    history.push(`/dashboard/editor/${jobId}?userType=${userType}`);
  };
  const resumeJobWithNewEditor = jobId => {
    history.push(
      `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
        activeKey
      )}&userRole=${userType1}`
    );
  };

  const viewJob = jobId => {
    history.push(
      `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
        activeKey
      )}&userRole=${userType1}`
    );
  };

  const releaseJobHandler = jobId => {
    setReleaseJobId(jobId);
    setReleaseJobModalVisible(true);
  };
  const releaseJobConfirmHandler = () => {
    releaseJob(releaseJobId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Job released',
            description: response.data.message
          });
          fetchJobList(currentPage);
          fetchSidebarList();
          setReleaseJobModalVisible(false);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };

  const resubmit = jobIds => {
    setResubmitJobIds(jobIds);
    setResubmitLoading(true);
    setResubmitModalLoading(true);
    setResubmitModalVisible(true);
    setResubmitModalLoading(false);
  };

  const handleResubmitConfirm = jobIds => {
    console.log('new', jobIds);
    setResubmitModalLoading(true);
    retryIncompleteJobSubmission(jobIds, user.sessionId)
      .then(response => {
        if (response.data.success) {
          fetchSidebarList();

          fetchJobList(0, selectedTab);
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setResubmitModalVisible(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
        setResubmitModalLoading(false);
        setResubmitLoading(false);
      })
      .catch(err => {
        setResubmitLoading(false);
      });
  };

  // const modifyConfigObject = updatedFilterConfig => {
  //   console.log('updatedFilterConfig', updatedFilterConfig);

  //   let ConfigData = [];
  //   for (const key in updatedFilterConfig) {
  //     if (updatedFilterConfig.hasOwnProperty(key)) {
  //       let obj = {
  //         key: String(key),
  //         type: 'string',
  //         values: updatedFilterConfig[key]
  //       };
  //       ConfigData.push(obj);
  //     }
  //   }
  //   console.log('updatedFilterConfig1', ConfigData);
  //   return ConfigData;
  // };
  const modifyConfigObject = updatedFilterConfig => {
    console.log('updatedFilterConfig', updatedFilterConfig);

    let ConfigData = [];
    for (const key in updatedFilterConfig) {
      if (updatedFilterConfig.hasOwnProperty(key)) {
        // Skip 'doctorIds' if activeTab1 is 'doctor'
        if (activeTab1 === 'doctor' && key === 'doctorIds') {
          continue;
        }

        // Always skip 'status'
        if (key === 'status') {
          continue;
        }

        let obj = {
          key: String(key),
          type: 'string',
          values: updatedFilterConfig[key]
        };
        ConfigData.push(obj);
      }
    }

    console.log('updatedFilterConfig1', ConfigData);
    return ConfigData;
  };

  const submitConfiguration = async () => {
    setLoading(true);
    setconfigLoader(true);
    let postObject = await modifyConfigObject(updatedFilterConfig);
    saveConfiguration(
      activeTab1 === 'doctor' ? 'myJobPage' : 'reviewerPage',
      postObject
    )
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Configured Job Filters',
            description: response.data.message
          });
          setconfigLoader(false);
          setAddDrawerVisible(false);
          modifyFilterResponse(response?.data?.result);
          fetchJobList(0, activeTab1);
          fetchSidebarList();
          setCurrentPage(0);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setconfigLoader(false);
        }
      })
      .catch(err => {
        setconfigLoader(false);
      });
  };

  const resetFilter = async () => {
    let resetData = JSON.stringify(filterConfig);
    setUpdatedFilterConfig(JSON.parse(resetData));
  };

  return (
    <>
      <PageTitle
        title={
          userType === 'transcriptionist' ? 'Joblist' : 'Transcription List'
        }
      />
      {loading && tableLoading ? (
        <Spinner />
      ) : (
        <ContainerRow>
          <TitleRow justify='space-between'>
            <CustomTypography color={theme['@text-color']}>
              {userType === 'transcriptionist' ? 'Job list' : 'Job list'}
            </CustomTypography>
            <ConfigureButton
              type={'default'}
              shape='round'
              onClick={() => setAddDrawerVisible(true)}
            >
              <StyledContent>
                <FilterButtonTittle>Filter</FilterButtonTittle>
                <VectorIcon />
                {filterCount > 0 ? <FilterBadge /> : ''}
              </StyledContent>
            </ConfigureButton>
          </TitleRow>

          {userRoles?.includes('ROLE_DOCTOR') ||
          userRoles?.includes('ROLE_REVIEWER') ||
          userRoles?.includes('ROLE_ADMIN') ? (
            <StyledJobListSubTab
              tabswidth={'500px'}
              defaultActiveKey={activeTab1}
              activeKey={activeTab1}
              onChange={handleTabChange}
              style={{
                pointerEvents: tableLoading ? 'none' : 'auto', // Prevents clicking
                opacity: tableLoading ? 0.6 : 1 // Dims the UI
              }}
            >
              {(userRoles?.includes('ROLE_DOCTOR') ||
                userRoles?.includes('ROLE_ADMIN')) && (
                <TabPane tab='My Jobs' key='doctor' />
              )}
              {(userRoles?.includes('ROLE_REVIEWER') ||
                userRoles?.includes('ROLE_ADMIN')) && (
                <TabPane tab='Jobs to review' key='reviewer' />
              )}
            </StyledJobListSubTab>
          ) : (
            <p>No Access</p> // Show message if user has no access to any tabs
          )}
          <div>
            <StyledJobListTab
              onChange={onSelect}
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              style={{
                pointerEvents: tableLoading ? 'none' : 'auto', // Prevents clicking
                opacity: tableLoading ? 0.6 : 1 // Dims the UI
              }}
            >
              {sideBarItems?.map(sideBarItem => {
                // const isTranscriptionPermission =
                //   user.userType === 'admin' ||
                //   user.userType === 'clinicAdmin' ||
                //   (user.userType === 'doctor' &&
                //     user.userPrivilege === 'TRANSCRIPTION_REVIEWER');
                return (
                  <StyledJobListTabPane
                    className='tab-pane'
                    tab={
                      <Link
                        to={sideBarItem.path}
                        onClick={() => {
                          setCurrentPage(0);
                          console.log('hi1', userType);

                          setActiveIncompletetab('failed');
                          setActivetab(userType);
                          setUserType(userType);

                          // Set status based on key
                          setStatus(statusMapping[sideBarItem.key]);
                        }}
                      >
                        <StyledJobListTabPaneContent className='tab-content'>
                          <StyledJobListTabPaneContentText className='tab-content-text'>
                            {sideBarItem.name}
                          </StyledJobListTabPaneContentText>
                          {/* <Badge
                            dot={
                              sideBarItem.name === 'Ready' &&
                              notificationStatus.newReadyJobs?.length > 0
                            }
                            color={theme['@primary-color']}
                            className='tab-content-count-badge'
                          >
                            <StyledJobListTabPaneContentCount className='tab-content-count'>
                              {sideBarItem.count}
                            </StyledJobListTabPaneContentCount>
                          </Badge> */}
                        </StyledJobListTabPaneContent>
                      </Link>
                    }
                    key={sideBarItem.key}
                  />
                );
              })}
            </StyledJobListTab>
          </div>

          <TableDiv>
            <JobListTable
              setselectedTab={setselectedTab}
              titleKey={activeKey}
              currentPage={currentPage}
              total={total}
              filterKey={filterKey}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setCurrentPage={setCurrentPage}
              pageNoChangeHandler={pageNoChangeHandler}
              setFilterKey={setFilterKey}
              setSearchKey={setSearchKey}
              availableJobs={availableJobs}
              activeKey={activeKey}
              assignToSelf={assignToSelf}
              assignButtonLoading={assignButtonLoading}
              startAssignedJob={startAssignedJob}
              startAssignedJobWithNewEditor={startAssignedJobWithNewEditor}
              resumeJob={resumeJob}
              resumeJobWithNewEditor={resumeJobWithNewEditor}
              viewJob={viewJob}
              releaseJobHandler={releaseJobHandler}
              tableLoading={tableLoading}
              activeTab={activeTab}
              setActivetab={setActivetab}
              activeIncompleteTab={activeIncompleteTab}
              setActiveIncompletetab={setActiveIncompletetab}
              fetchJobList={fetchJobList}
              setUserType={setUserType}
              userType={userType}
              jobStatus={jobStatus}
              setJobStatus={setJobStatus}
              counts={counts}
              selfAssignTranscriptorBadge={selfAssignTranscriptorBadge}
              setSelfAssignedTranscriptorBadge={
                setSelfAssignedTranscriptorBadge
              }
              selfAssignReviewerBadge={selfAssignReviewerBadge}
              setSelfAssignedReviewerBadge={setSelfAssignedReviewerBadge}
              searchVal={searchVal}
              setSearchVal={setSearchVal}
              type={type}
              resubmit={resubmit}
              resubmitLoading={resubmitLoading}
              setFacilities={setFacilities}
            />
          </TableDiv>
        </ContainerRow>
      )}
      <ConfirmationModal
        antdModalProps={{ centered: true, width: '300', closable: false }}
        visible={resubmitModalVisible}
        onCancel={() => {
          setResubmitModalVisible(false);
          setResubmitLoading(false);
        }}
        onOk={() => handleResubmitConfirm(resubmitJobIds)}
        header={
          <CustomTypography color={theme['@text-color']}>
            Are you sure?
          </CustomTypography>
        }
        confirmation={true}
        loading={resubmitModalLoading}
      >
        <h1>{`Do you really want to resubmit?`}</h1>
      </ConfirmationModal>
      <ReleaseJobModal
        releaseJobModalVisible={releaseJobModalVisible}
        setReleaseJobModalVisible={setReleaseJobModalVisible}
        onConfirm={releaseJobConfirmHandler}
      />
      <CustomSideDrawer
        filter={true}
        title={'Filter By'}
        placement='right'
        closable={true}
        onClose={() => {
          setAddDrawerVisible(false);
        }}
        visible={addDrawerVisible}
        width={400}
        destroyOnClose={true}
        extra={
          <Space>
            <StyledResetButton
              danger
              type='text'
              onClick={event => resetFilter()}
            >
              Reset Filter
            </StyledResetButton>
          </Space>
        }
        footer={
          <SubmitButtonCol span={24}>
            <SubmitButton
              htmlType='submit'
              type='primary'
              size='large'
              shape='round'
              onClick={event => submitConfiguration()}
              disabled={
                configLoader ||
                JSON.stringify(filterConfig) ===
                  JSON.stringify(updatedFilterConfig)
              }
              loading={configLoader}
            >
              Save Configurations
            </SubmitButton>
          </SubmitButtonCol>
        }
      >
        <FilterConfiguration
          jobStatus={type}
          Modified={updatedFilterConfig}
          setModified={setUpdatedFilterConfig}
          facilities={userfacilities}
          Authors={authors}
          activeTab={activeTab1}
        />
      </CustomSideDrawer>
    </>
  );
};

export default JobList;
